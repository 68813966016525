import ThemisReportMessage from "@/components/report/message"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { compareDates } from "@/utils"
import { ITEM_COMPARISON } from "@/constants"
import moment from "moment"

export default {
  name      : "ReportMessages",
  components: {
    ThemisReportMessage,
    ThemisDecision,
    ThemisInput
  },
  data: () => ({
    showCloseReportDialog              : false,
    showMessagesInClientDefaultLanguage: true,
    canClose                           : false,
    isEditingMessage                   : false,
    closingRemark                      : ""
  }),
  props: {
    pReport                             : Object,
    pChannel                            : Object,
    pLanguages                          : Array,
    pUsers                              : Array,
    pIsMessageAdded                     : Boolean,
    pIsAddingMessage                    : Boolean,
    pIsRemovingMessage                  : Boolean,
    pIsUpdatingMessageItemValue         : Boolean,
    pIsSendingMessage                   : Boolean,
    pIsMessageSent                      : Boolean,
    pIsStatusUpdated                    : Boolean,
    pIsUpdatingStatus                   : Boolean,
    pIsUpdatingTranslationStatus        : Boolean,
    pIsTranslationStatusUpdated         : Boolean,
    pIsAddingTranslation                : Boolean,
    pIsTranslationAdded                 : Boolean,
    pReportUpdatePolicy                 : Object,
    pReportAddMessagesPolicy            : Object,
    pReportMessageUpdatePolicies        : Array,
    pReportMessageRemovePolicies        : Array,
    pTranslationUpdatePolicies          : Array,
    pMessageItemAddTranslationsPolicies : Array,
    pIsReportOpen                       : Boolean,
    pIsVisible                          : Boolean,
    pIsReportAnonymised                 : Boolean,
    pIsMessageRemoved                   : Boolean,
    pReplyTemplates                     : Array,
    pIsReplyTemplatesEnabled            : Boolean,
    pHighlightText                      : String,
    pShowTranslations                   : Boolean,
    pShowMessagesInClientDefaultLanguage: Boolean,
    pAiSpeechToText                     : Boolean,
    pIsUpdatingMessageApproval          : Boolean,
    pIsMessageApprovalUpdated           : Boolean
  },

  computed: {
    sortedMessages() {
      let sortedMessages = new Array()
      if (this.pReport.messages) {
        sortedMessages = this.pReport.messages.slice().sort((item1, item2) => {
          if (item1.sentAt && item2.sentAt) {
            return compareDates(item1.sentAt, item2.sentAt)
          } else if (!item1.sentAt && !item2.sentAt) {
            return compareDates(item1.createdAt, item2.createdAt)
          } else if (item1.sentAt) {
            return ITEM_COMPARISON.FIRST_ITEM_IS_LOWER
          } else {
            return ITEM_COMPARISON.FIRST_ITEM_IS_HIGHER
          }
        })
      }
      return sortedMessages
    },
    isReportNotInClientDefaultLanguage() {
      return this.pReport.languageId !== this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id
    },
    reportLanguage() {
      return this.pLanguages.find(language => language.id === this.pReport.languageId)
    },
    draftReplyMessage() {
      return this.pReport?.messages.find(message =>
        message.userId !== null && message.status === this.$CONSTANTS.MESSAGE_STATUS.DRAFT
      )
    },
    latestMessage() {
      return this.pReport?.messages?.reduce((message1, message2) =>
        new Date(message1.createdAt) > new Date(message2.createdAt) ? message1 : message2
      )
    },
    replyMessageLanguages() {
      const replyMessageLanguages = [this.reportLanguage]
      if (!(this.pChannel?.effectiveTranslationPreference === this.$CONSTANTS.TRANSLATION_PREFERENCES.NO_TRANSLATION)
        && this.isReportNotInClientDefaultLanguage
      ) {
        replyMessageLanguages.push(this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT)
      }
      return replyMessageLanguages
    },
    canAddMessages() {
      return !!this.pReportAddMessagesPolicy?.set?.add
    },
    getDateForLastSeen() {
      const date = this.pReport.lastSeen
      return `${moment(date).format("D MMM, HH:mm")}.`
    },
    getTooltipForLastSeen() {
      const date       = this.pReport.lastSeen
      const dateString = moment(date).format("D MMMM YYYY")
      const timeString = moment(date).format("HH:mm")
      const offset     = moment(date).format("Z")
      return this.$t("2432", { dateString, timeString, offset })
    },
    reportIntakeType() {
      return this.pReport.intakeType
    },
    closingAiAgentReport() {
      return this.pIsAddingMessage || this.pIsUpdatingStatus
    }
  },

  methods: {
    handleSaveDraftMessage() {
      this.$emit("saveMessageItem", ...arguments)
    },
    handleSendDraftMessage() {
      this.$emit("sendMessage", ...arguments)
    },
    async handleCloseReport() {
      if (this.reportIntakeType === this.$CONSTANTS.REPORT_INTAKE_TYPE.AI_AGENT && this.closingRemark) {
        this.$emit("newMessage", this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT, this.closingRemark, true)
      } else {
        const report = {
          id    : this.pReport.id,
          status: "closed"
        }
        this.$emit("updateReport", report)
      }
    },
    getMessageUpdatePolicy(messageId) {
      return this.pReportMessageUpdatePolicies.find(messageUpdatePolicy => messageUpdatePolicy.id === messageId)
    },
    getMessageRemovePolicy(messageId) {
      return this.pReportMessageRemovePolicies.find(messageRemovePolicy => messageRemovePolicy.id === messageId)
    },
    getTranslationUpdatePolicy(translation) {
      if (translation) {
        return this.pTranslationUpdatePolicies.find(translationUpdatePolicy =>
          translation.id === translationUpdatePolicy.id
        )
      }
    },
    updateTranscriptionApproval(payload) {
      this.$emit("sendTranscriptionApprovalToIssue", payload)
    },
    handleUpdateQuestions(payload) {
      this.$emit("updateQuestions", payload)
    },
    handleCancelCloseReport() {
      this.showCloseReportDialog = false
      this.closingRemark         = ""
    }
  },

  watch: {
    pIsVisible: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue && this.latestMessage) {
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          const element = this.$refs[`message_${this.latestMessage.id}`][0]
          this.$vuetify.goTo(element, {
            duration: 500,
            offset  : 0,
            easing  : "easeInOutCubic"
          })
        }
      }
    },
    pReportUpdatePolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          const canUpdateReportStatus = !!newValue.set.status
          if (canUpdateReportStatus) {
            this.canClose = newValue.set.status.length ? newValue.set.status.includes("closed") : true
          }
        }
      }
    },
    pIsStatusUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.showCloseReportDialog = false
          this.closingRemark         = ""
        }
      }
    },
    pIsUpdatingStatus: {
      handler: function(newValue) {
        this.$DECISIONS.CLOSE_REPORT.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.CLOSE_REPORT.pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsMessageSent: {
      handler: function(newValue) {
        if (newValue) {
          this.isEditingMessage = false
        }
      }
    },
    pIsMessageRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.isEditingMessage = false
        }
      }
    }
  }
}