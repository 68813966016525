import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import moment from "moment"
import {
  REPORT_SOURCE,
  CLIENT_REPLY_MESSAGE_LIMIT,
  TRANSCRIPTION_APPROVAL_STATUS,
  TRANSCRIPTION_NOT_APPROVED_OPTIONS,
  RECEIVE_VOICE_FILE_TRANSCRIPTION_APPROVAL_GOTTEN,
  MESSAGE_QUESTIONS_SEPERATOR,
  MAX_CHARACTER_LIMIT
} from "@/constants"
import ThemisAudio from "../audio"

export default {
  name      : "ReportMessage",
  components: {
    ThemisInput,
    ThemisDecision,
    ThemisAudio
  },
  data: () => ({
    originalAudioMessageItem                  : undefined,
    machineRecordedAudionMessageItem          : undefined,
    originalTextMessageItem                   : undefined,
    machineTranslatedTextMessageItem          : undefined,
    machineTranscribedTextMessageItem         : undefined,
    humanTranslatedTextMessageItem            : undefined,
    humanTranscribedTextMessageItem           : undefined,
    attachments                               : new Array(),
    showSideBySide                            : false,
    editDraftMessage                          : false,
    localDraftTextMessageValue                : "",
    draftTextMessageLastSavedTime             : undefined,
    draftMessageAutoSaveInterval              : undefined,
    sendMessageDecision                       : undefined,
    sendMessageDecisionNote                   : undefined,
    humanTranslateManually                    : undefined,
    showRequestHumanTranslationDialog         : false,
    showRedoHumanTranslationDialog            : false,
    showDiscardDraftMessageDialog             : false,
    showSendMessageToReporterDialog           : false,
    translationRedoReason                     : "",
    citationFound                             : false,
    messageSegments                           : [],
    citations                                 : [],
    expandedMachineItems                      : [],
    expandedHumanItems                        : [],
    hoverOnAiTranscribedIcon                  : false,
    transcriptApprovalAndFileStorageToBeChosen: false,
    transcriptApprovalListValue               : false,
    selectedTranscriptApprovalValue           : null,
    reporterDidNotApproveTranscription        : null,
    showRegisterTranscriptApprovalDialog      : false,
    reporterApprovedTranscript                : null,
    approvedTranscriptOptions                 : null,
    isTranscriptApprovedByReporter            : null,
    showTranscriptApprovalAndFileStorageDialog: false,
    onlyVoiceFile                             : "onlyVoiceFile",
    onlyTranscript                            : "onlyTranscript",
    transcriptAndAudio                        : "transcriptAndAudio",
    showConversation                          : false,
    conversationMessageItem                   : [],
    showConversationInClientDefaultLanguage   : true,
    aiConversationAudio                       : undefined,
    originalQuestionsMessageItems             : undefined,
    currentQuestion                           : "",
    questions                                 : [],
    translatedQuestions                       : null,
    questionCharacterLimit                    : MAX_CHARACTER_LIMIT.AI_AGENT_QUESTIONS
  }),
  props: {
    pMessage                            : Object,
    pReportLanguage                     : Object,
    pReportSource                       : String,
    pReportIntakeType                   : String,
    pUsers                              : Array,
    pChannel                            : Object,
    pLanguages                          : Array,
    pIsMessageAdded                     : Boolean,
    pIsRemovingMessage                  : Boolean,
    pIsUpdatingMessageItemValue         : Boolean,
    pIsSendingMessage                   : Boolean,
    pIsMessageSent                      : Boolean,
    pIsReportOpen                       : Boolean,
    pIsUpdatingTranslationStatus        : Boolean,
    pIsTranslationStatusUpdated         : Boolean,
    pIsAddingTranslation                : Boolean,
    pIsTranslationAdded                 : Boolean,
    pShowMessagesInClientDefaultLanguage: Boolean,
    pIsReportNotInClientDefaultLanguage : Boolean,
    pMessageUpdatePolicy                : Object,
    pMessageRemovePolicy                : Object,
    pTranslationUpdatePolicy            : Object,
    pMessageItemAddTranslationsPolicies : Array,
    pIsReportAnonymised                 : Boolean,
    pReplyTemplates                     : Array,
    pIsReplyTemplatesEnabled            : Boolean,
    pHighlightText                      : String,
    pShowTranslations                   : Boolean,
    pAiSpeechToText                     : Boolean,
    pIsUpdatingMessageApproval          : Boolean,
    pIsMessageApprovalUpdated           : Boolean
  },
  beforeDestroy() {
    if (this.editDraftMessage && this.draftMessageAutoSaveInterval) {
      clearInterval(this.draftMessageAutoSaveInterval)
    }
  },
  computed: {
    isSaveButtonDisabled() {
      return this.reporterApprovedTranscript === null ||
      (this.reporterApprovedTranscript && this.approvedTranscriptOptions === null) ||
      (this.reporterApprovedTranscript === false && this.approvedTranscriptOptions === null)
    },
    sender() {
      return this.pUsers.find(user =>
        user.id === this.pMessage.userId
      )
    },
    reporterMessage() {
      return !this.pMessage.userId
    },
    isVoiceFile() {
      return !this.pMessage.userId
        && this.pMessage.voiceFile !== null
        && !this.isAiAgentReport
    },
    isAiDigitalAgentMessage() {
      return this.pReportSource === this.$CONSTANTS.REPORT_SOURCE.PHONE
        && this.isAiAgentReport
        && !this.sender
    },
    isVoiceFileScrambledOrOriginal() {
      if (this.pMessage.voiceFile === this.$CONSTANTS.VOICE_FILE.SCRAMBLED) {
        return this.$t("2542")
      } else {
        return this.$t("2543")
      }
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.pLanguages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    isSeenByReporter() {
      return this.sender && this.pMessage.status === this.$CONSTANTS.MESSAGE_STATUS.SEEN
    },
    isDraftMessage() {
      return this.pMessage?.status === this.$CONSTANTS.MESSAGE_STATUS.DRAFT
    },
    getMachineTranslatedTextMessageItemValue() {
      return this.machineTranslatedTextMessageItem?.value
    },
    getMachineTranscribedTextMessageItemValue() {
      return this.machineTranscribedTextMessageItem?.value
    },
    getHumanTranslatedTextMessageItemValue() {
      return this.humanTranslatedTextMessageItem?.value
    },
    getHumanTranscribedTextMessageItemValue() {
      return this.humanTranscribedTextMessageItem?.value
    },
    messageItemToBeDisplayed() {
      if (this.sender) {
        if (this.pShowMessagesInClientDefaultLanguage) {
          return this.originalTextMessageItem
        } else {
          if (this.humanTranslatedTextMessageItem?.value) {
            return this.humanTranslatedTextMessageItem
          } else if (this.machineTranslatedTextMessageItem?.value) {
            return this.machineTranslatedTextMessageItem
          } else {
            return this.originalTextMessageItem
          }
        }
      } else {
        if (this.pShowMessagesInClientDefaultLanguage) {
          if (this.humanTranslatedTextMessageItem?.value) {
            return this.humanTranslatedTextMessageItem
          } else if (this.isTranscriptRemoved !== true && this.machineTranslatedTextMessageItem?.value) {
            return this.machineTranslatedTextMessageItem
          } else if(this.humanTranscribedTextMessageItem?.value) {
            return this.humanTranscribedTextMessageItem
          } else if (this.isTranscriptRemoved !== true && this.machineTranscribedTextMessageItem?.value) {
            return this.machineTranscribedTextMessageItem
          } else {
            return this.originalTextMessageItem
          }
        } else {
          if (this.originalTextMessageItem) {
            return this.originalTextMessageItem
          } else if (this.humanTranscribedTextMessageItem?.value) {
            return this.humanTranscribedTextMessageItem
          } else if (this.isTranscriptRemoved !== true && this.machineTranscribedTextMessageItem?.value) {
            return this.machineTranscribedTextMessageItem
          } else if (this.humanTranslatedTextMessageItem?.value) {
            return this.humanTranslatedTextMessageItem
          } else if (this.isTranscriptRemoved !== true && this.machineTranslatedTextMessageItem?.value) {
            return this.machineTranslatedTextMessageItem
          }
        }
      }
    },
    manageTranscriptApproval() {
      return this.pMessage.voiceFile !== null && this.originalAudioMessageItem
      && this.pMessage.aiTranscriptApproved === null
    },
    isTranslated() {
      return (this.originalTextMessageItem && this.humanTranslatedTextMessageItem?.value) ||
        (this.originalTextMessageItem && this.machineTranslatedTextMessageItem?.value)
    },
    showMenuForMessage() {
      return this.pShowTranslations && (
        this.canRequestHumanTranslationForReporterMessage ||
        this.canRequestRedoOfTranslation ||
        this.canApproveTranscript ||
        this.showSideBySideOption ||
        this.isAiDigitalAgentMessage
      )
    },
    attachmentNames() {
      let name
      if (this.attachments.length) {
        if (!this.attachments[0].value) {
          name = this.$tc("783", this.attachments.length)
        } else {
          name = this.attachments[0].value.name
          for (let index = 1; index < this.attachments.length; index++) {
            name += `, ${this.attachments[index].value.name}`
          }
        }
      }
      return name
    },
    isTranslationRequiredForReplyMessage() {
      return this.isDraftMessage &&
        (this.originalTextMessageItem.language.id !== this.pReportLanguage.id ||
          (this.pReportSource === this.$CONSTANTS.REPORT_SOURCE.PHONE &&
            !this.isAiAgentReport
          )
        )
    },
    isMessageInTranslation() {
      return !!(["open", "redo"].includes(this.pMessage?.translation?.status))
    },
    isTranslationUnintelligible() {
      return !!(this.pMessage?.translation?.resolution === "Unintelligible")
    },
    isTranslationLanguageChangeDeclined() {
      return !!(this.pMessage?.translation?.resolution === "Rejected exception approval")
    },
    isTranslationLanguageChangeApproved() {
      if (this.originalTextMessageItem) {
        return !!(this.pMessage?.translation?.resolution === "Done" && this.originalTextMessageItem.languageId !== this.pMessage?.translation.sourceLanguageId)
      }
      return false
    },
    areTranslationLanguagesSame() {
      return !!(this.pMessage?.translation?.resolution === "Target language equals source language")
    },
    canRequestHumanTranslationForReporterMessage() {
      if (this.pMessage.voiceFileRemoved) {
        return false
      }
      const originalMessageItem = this.originalAudioMessageItem || this.originalTextMessageItem
      if (originalMessageItem) {
        const messageItemAddTranslationPolicy =
          this.pMessageItemAddTranslationsPolicies.find(messageItemAddTranslationPolicy =>
            messageItemAddTranslationPolicy.id === originalMessageItem.id &&
            (messageItemAddTranslationPolicy?.set?.add && !messageItemAddTranslationPolicy.set.add.length)
          )
        if (messageItemAddTranslationPolicy && this.originalTextMessageItem) {
          return !this.sender && !this.pMessage.translation && this.pIsReportNotInClientDefaultLanguage &&
            this.pChannel.effectiveTranslationPreference === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED
        }
        if (messageItemAddTranslationPolicy && this.originalAudioMessageItem) {
          return !this.sender && !this.pMessage.translation &&
            this.pChannel.effectiveTranslationPreference === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED
        }
      }
    },
    canRequestRedoOfTranslation() {
      if (!this.sender && this.pMessage.translation?.status === "done" && this.pTranslationUpdatePolicy?.set?.status) {
        if (this.pTranslationUpdatePolicy.set.status.includes("redo")) {
          return this.$moment().diff(this.pMessage.translation.closedAt) / (60 * 60 * 24 * 1000) < 14
        }
      }
    },
    canMachineTranslateAndSend() {
      return this.isTranslationRequiredForReplyMessage &&
        ((this.pReportSource !== this.$CONSTANTS.REPORT_SOURCE.PHONE) ||
        (this.pReportSource === this.$CONSTANTS.REPORT_SOURCE.PHONE &&
          !this.isAiAgentReport &&
          this.pAiSpeechToText &&
          this.languagesMap[this.pReportLanguage.id]?.machineTranscribable)) &&
        this.languagesMap[this.pReportLanguage.id]?.machineTranslatable &&
        [this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED,
          this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY].
          includes(this.pChannel.effectiveTranslationPreference)
    },
    canSendViaTranslator() {
      return this.isTranslationRequiredForReplyMessage && !this.isAiAgentReport &&
        this.languagesMap[this.pReportLanguage.id]?.humanTranslatable &&
        [this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED,
          this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ONLY].
          includes(this.pChannel.effectiveTranslationPreference)
    },
    canSendMessage() {
      return this.pMessageUpdatePolicy?.set?.status ? this.pMessageUpdatePolicy.set.status.includes("new") : false
    },
    canChangeTranscriptApprovalStatus() {
      return this.pMessageUpdatePolicy?.set?.aiTranscriptApproved
    },
    canApproveTranscription() {
      return this.canChangeTranscriptApprovalStatus
        && (this.machineTranscribedTextMessageItem?.value || this.humanTranscribedTextMessageItem?.value)
        && !this.pMessage.voiceFile
        && (this.pMessage.aiTranscriptApproved === null)
    },
    canEditMessage() {
      // for now using send message permission for edit message also. should be replaced with message item update permission.
      return this.canSendMessage
    },
    canRemoveMessage() {
      if (this.pMessageRemovePolicy?.set?.remove) {
        return !this.pMessageRemovePolicy.set.remove.length
      }
      return false
    },
    canShowReplyTemplateForReplyMessage() {
      return this.isDraftMessage &&
        this.originalTextMessageItem.language.id === this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id
    },
    isMessageAiTranscribedAndTranslated() {
      return ((this.originalAudioMessageItem &&
        this.machineTranscribedTextMessageItem?.value &&
        this.machineTranslatedTextMessageItem?.value) ||
      (this.pMessage.voiceFileRemoved &&
        this.machineTranscribedTextMessageItem?.value &&
        this.machineTranslatedTextMessageItem?.value
      ))
    },
    isMessageAiTranscribed() {
      return this.originalAudioMessageItem &&
        this.machineTranscribedTextMessageItem?.value
    },
    isMachineTranslated() {
      return this.machineTranslatedTextMessageItem?.value
    },
    isHumanTranslated() {
      return this.humanTranslatedTextMessageItem?.value
    },
    isMessageHumanTranscribedAndTranslated() {
      return ((this.originalAudioMessageItem &&
        this.humanTranscribedTextMessageItem?.value &&
        this.humanTranslatedTextMessageItem?.value) ||
      (this.pMessage.voiceFileRemoved &&
        this.humanTranscribedTextMessageItem?.value &&
        this.humanTranslatedTextMessageItem?.value
      ))
    },
    isMessageHumanTranscribed() {
      return this.originalAudioMessageItem &&
        this.humanTranscribedTextMessageItem?.value
    },
    isMessageTranscribed() {
      return this.isMessageAiTranscribed && !this.isVoiceFile
    },
    getTooltipTextForHumanTranslationIcon() {
      if (this.isMessageHumanTranscribedAndTranslated) {
        return this.$t("2084")
      } else if (this.isMessageHumanTranscribed) {
        return this.$t("2085")
      } else {
        return this.$t("346")
      }
    },
    showToolTipForMachineTranscribableEchoReport() {
      return this.pReportSource === REPORT_SOURCE.PHONE &&
      this.languagesMap[this.pReportLanguage.id]?.machineTranscribable &&
      this.machineRecordedAudionMessageItem
    },
    showTooltipForMessageItem() {
      return this.isMessageAiTranscribedAndTranslated
        || this.isMessageAiTranscribed
        || this.isMachineTranslated
        || this.showToolTipForMachineTranscribableEchoReport
    },
    showSideBySideOption() {
      return this.isTranslated || ((this.machineTranscribedTextMessageItem?.value &&
        this.machineTranslatedTextMessageItem?.value) ||
        (this.humanTranscribedTextMessageItem?.value &&
          this.humanTranslatedTextMessageItem?.value))
    },
    tooltipTextForTranslationIcon() {
      if (this.showToolTipForMachineTranscribableEchoReport) {
        return this.$t("2499")
      } else {
        if (this.isMessageAiTranscribedAndTranslated) {
          return this.$t("2448")
        } else if (this.isMessageAiTranscribed) {
          return this.$t("2446")
        } else {
          return this.$t("347")
        }
      }
    },
    showExpandIcon() {
      return !!(this.humanTranscribedTextMessageItem?.value
      && this.humanTranslatedTextMessageItem?.value
      && this.machineTranscribedTextMessageItem?.value
      && this.machineTranslatedTextMessageItem?.value)
    },
    isAiTranscriptApproved() {
      return this.pMessage.aiTranscriptApproved
    },
    isAiTranscriptNotApproved() {
      return !this.pMessage.aiTranscriptApproved && this.pMessage.aiTranscriptApproved !== null
    },
    isTranscriptionRemoved() {
      return this.pMessage.aiTranscriptRemoved && this.pMessage.voiceFile !== null
    },
    isTranscriptRemoved() {
      return this.pMessage.aiTranscriptRemoved
    },
    transcriptRemoved() {
      if (this.isTranscriptionRemoved) {
        return this.$t("2547")
      } else if (this.isTranscriptRemoved) {
        return this.$t("2544")
      }
    },
    isVoiceFileRemoved() {
      return this.pMessage.voiceFileRemoved
    },
    getTooltipForPreApprovedNoTranslation() {
      const sentAtTimestamp = this.pMessage.aiTranscriptAt
      const formattedDate   = moment(sentAtTimestamp).format("D MMMM YYYY")
      if (this.isAiTranscriptApproved) {
        return this.$t("2433", { formattedDate })
      } else {
        return this.$t("2536")
      }
    },
    messageTranslatedNotTranscribed() {
      return !this.humanTranscribedTextMessageItem?.value &&
        !this.machineTranscribedTextMessageItem?.value &&
        this.humanTranslatedTextMessageItem?.value &&
        this.machineTranslatedTextMessageItem?.value
    },
    reporterOrClientLanguageText() {
      if (this.sender) {
        return this.$t("1665")
      } else if (this.humanTranscribedTextMessageItem?.value) {
        return this.$t("2439")
      } else {
        return this.$t("400")
      }
    },
    showAdditionalColForMachineItem() {
      return this.showExpandIcon && this.expandedMachineItems.length === 0
    },
    showAdditionalColForHumanItem() {
      return this.showExpandIcon && this.expandedHumanItems.length === 0
    },
    showHumanFaceIcon() {
      return!!(this.isHumanTranslated ||
      this.isMessageHumanTranscribedAndTranslated ||
      this.isMessageHumanTranscribed)
    },
    maxLengthForReplyMessage() {
      return this.pReportSource === REPORT_SOURCE.PHONE
        ? CLIENT_REPLY_MESSAGE_LIMIT.ECHO
        : CLIENT_REPLY_MESSAGE_LIMIT.OTHERS
    },
    replyMessageCounter() {
      const locale = this.pReportSource === REPORT_SOURCE.PHONE ? "2500" : "481"
      return this.$t(locale, { length: this.localDraftTextMessageValue.length })
    },
    hasReporterApprovedTranscript() {
      return (this.pMessageUpdatePolicy?.set?.aiTranscriptApproved)
        && (this.machineTranscribedTextMessageItem?.value || this.isAiAgentReport)
        && this.pMessage.voiceFile
        && this.pMessage.aiTranscriptApproved === null
    },
    transcriptApprovalAndFileStorageItems() {
      return [
        {
          name : this.$t("2528"),
          value: TRANSCRIPTION_APPROVAL_STATUS.APPROVED
        },
        {
          name : this.$t("2529"),
          value: TRANSCRIPTION_APPROVAL_STATUS.NOT_APPROVED
        }
      ]
    },
    isTranscriptApprovalSaveButtonDisabled() {
      return !this.selectedTranscriptApprovalValue || (
        this.selectedTranscriptApprovalValue === TRANSCRIPTION_APPROVAL_STATUS.NOT_APPROVED &&
        this.isTranscriptApprovedByReporter === null
      )
    },
    canApproveTranscript() {
      return this.canApproveTranscription || this.hasReporterApprovedTranscript
    },
    transcriptApprovalListItem() {
      if (this.canApproveTranscription) {
        return this.$t("2525")
      } else if (this.hasReporterApprovedTranscript) {
        return this.$t("2513")
      }
    },
    isTranscriptApproved() {
      return this.selectedTranscriptApprovalValue === TRANSCRIPTION_APPROVAL_STATUS.APPROVED
    },
    isTranscriptNotApproved() {
      return this.selectedTranscriptApprovalValue === TRANSCRIPTION_APPROVAL_STATUS.NOT_APPROVED
    },
    getColorForTranscriptAlert() {
      return this.isTranscriptApproved ? "blue lighten-5" : "red lighten-5"
    },
    getTextForTranscriptAlert() {
      return this.isTranscriptApproved ? this.$t("2532") : this.$t("2537")
    },
    getColorForTranscriptAlertIcon() {
      return this.isTranscriptApproved ? "blue lighten-1" : "red lighten-1"
    },
    audioUrl() {
      return this.originalAudioMessageItem?.fileUrl
    },
    isAudioExists() {
      return this.originalAudioMessageItem?.fileExists
    },
    getMessageClass() {
      return this.isAiDigitalAgentMessage ? ["message--ai", "pl-6", "pr-4"] : this.sender ? ["message--client", "pl-4", "pr-6"] : ["message--reporter", "pl-6", "pr-4"]
    },
    getDateClass() {
      return this.isAiDigitalAgentMessage ? "purple--text" : this.isSeenByReporter ? "secondary--text" : "info--text text--lighten-1"
    },
    isAiAgentReport() {
      return this.pReportIntakeType === this.$CONSTANTS.REPORT_INTAKE_TYPE.AI_AGENT
    },
    messageItemValueToBeDisplayed() {
      let messageItemValue = this.messageItemToBeDisplayed?.value
      if (this.questions.length && messageItemValue)
        messageItemValue += MESSAGE_QUESTIONS_SEPERATOR
      if (this.questions.length){
        if (this.pShowMessagesInClientDefaultLanguage){
          messageItemValue += this.questions.join("\n")
        } else {
          messageItemValue += this.translatedQuestions?.join("\n")
        }
      }
      return messageItemValue
    },
    disableSendMessageButton() {
      if (this.isAiAgentReport){
        return !this.localDraftTextMessageValue.length && !this.questions.length
      } else {
        return !this.localDraftTextMessageValue.length
      }
    }
  },
  methods: {
    showTranscriptApprovalDialog() {
      if (this.canApproveTranscription) {
        this.showTranscriptApprovalAndFileStorageDialog = true
      } else if (this.hasReporterApprovedTranscript) {
        this.showRegisterTranscriptApprovalDialog = true
      }
    },
    updateExpandedMachineItemsArray(id, value) {
      if (value) {
        this.expandedMachineItems.push(id)
      } else {
        this.expandedMachineItems = this.expandedMachineItems.filter(expandedId => expandedId !== id)
      }
    },
    updateExpandedHumanItemsArray(id, value) {
      if (value) {
        this.expandedHumanItems.push(id)
      } else {
        this.expandedHumanItems = this.expandedHumanItems.filter(expandedId => expandedId !== id)
      }
    },
    isExpandedHumanItems(id) {
      return this.expandedHumanItems.includes(id)
    },
    getLanguageCode(isExpanded, messageItem) {
      if (isExpanded) {
        return messageItem.language.name
      } else {
        return messageItem.language.shortName.toUpperCase()
      }
    },
    isExpandedMachineItems(id) {
      return this.expandedMachineItems.includes(id)
    },
    getDateForMessageDisplay(date) {
      return moment(date).format("D MMM, HH:mm")
    },
    getTooltipForMessageDisplay(date) {
      const dateString = moment(date).format("D MMMM YYYY")
      const timeString = moment(date).format("HH:mm")
      const offset     = moment(date).format("Z")
      return this.$t("2432", { dateString, timeString, offset })
    },
    handleClick(caller) {
      this.humanTranslateManually = false
      switch (caller) {
        case "button_send": {
          if (this.pReportSource === REPORT_SOURCE.PHONE && this.pAiSpeechToText
            && this.languagesMap[this.pReportLanguage.id]?.machineTranscribable
            && this.pChannel.effectiveTranslationPreference !== this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ONLY
          ) {
            this.sendMessageDecision     = "2501"
            this.sendMessageDecisionNote = "327"
            break
          }
          if (!this.isTranslationRequiredForReplyMessage) {
            this.sendMessageDecision     = "326"
            this.sendMessageDecisionNote = "327"
            break
          } else {
            if (this.canSendViaTranslator) {
              this.sendMessageDecision     = "330"
              this.sendMessageDecisionNote = "331"
            } else {
              this.sendMessageDecision     = "328"
              this.sendMessageDecisionNote = "329"
            }
            break
          }
        }
        case "button_machine_translate_and_send": {
          if (this.pReportSource === REPORT_SOURCE.PHONE && this.pAiSpeechToText
            && this.languagesMap[this.pReportLanguage.id]?.machineTranscribable
          ) {
            this.sendMessageDecision     = "2501"
            this.sendMessageDecisionNote = "327"
            break
          } else {
            this.sendMessageDecision     = "328"
            this.sendMessageDecisionNote = "329"
            break
          }
        }
        case "button_send_via_translator": {
          this.sendMessageDecision     = "330"
          this.sendMessageDecisionNote = "331"
          this.humanTranslateManually  = true
          break
        }
      }
      this.showSendMessageToReporterDialog = true
    },
    sendMessage() {
      clearInterval(this.draftMessageAutoSaveInterval)
      this.$emit("sendMessage", this.pMessage, {
        id                    : this.originalTextMessageItem.id,
        value                 : this.localDraftTextMessageValue,
        humanTranslateManually: this.humanTranslateManually
      })
    },
    useReplyTemplate(item) {
      this.localDraftTextMessageValue = item.content
    },
    getClassForExpansionHumanItems(id) {
      if (this.showExpandIcon) {
        if (this.expandedHumanItems.length === 2) {
          return "col-3"
        }
        if (this.expandedHumanItems.includes(id)) {
          return "col-5"
        }
        return "col-1"
      }
      if (this.messageTranslatedNotTranscribed) {
        return "col-4"
      }
      return "col-6"
    },
    getClassForExpansionMachineItems(id) {
      if (this.showExpandIcon) {
        if (this.expandedMachineItems.length === 2) {
          return "col-3"
        }
        if (this.expandedMachineItems.includes(id)) {
          return "col-5"
        }
        return "col-1"
      }
      if (this.messageTranslatedNotTranscribed) {
        return "col-4"
      }
      return "col-6"
    },
    getClassForSegment(segment) {
      for (const citation of this.citations) {
        if (citation.trim()) {
          if (segment.trim().toLowerCase().includes(citation.trim().toLowerCase())) {
            return "purple lighten-4"
          }
        }
      }
      return ""
    },
    handleRequestHumanTranslation() {
      if (this.originalAudioMessageItem) {
        if (this.originalAudioMessageItem.languageId === this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id) {
          this.$emit("requestHumanTranslation", { messageItemId: this.originalAudioMessageItem.id, type: "TRANSCRIBE" })
        } else {
          this.$emit("requestHumanTranslation", { messageItemId: this.originalAudioMessageItem.id, type: "TRANSCRIBE_AND_TRANSLATE" })
        }
      } else if (this.originalTextMessageItem) {
        this.$emit("requestHumanTranslation", { messageItemId: this.originalTextMessageItem.id, type: "TRANSLATE" })
      }
    },
    handleCancelTranscriptApproval() {
      this.showTranscriptApprovalAndFileStorageDialog = false
      this.selectedTranscriptApprovalValue            = null
    },
    saveTranscriptionApproval() {
      if (this.selectedTranscriptApprovalValue === TRANSCRIPTION_APPROVAL_STATUS.APPROVED) {
        this.$emit("sendTranscriptionApproval", {
          aiTranscriptApproved: true,
          aiTranscriptRemoved : false,
          id                  : this.pMessage.id
        })
      } else if (this.selectedTranscriptApprovalValue === TRANSCRIPTION_APPROVAL_STATUS.NOT_APPROVED) {
        if (this.isTranscriptApprovedByReporter === TRANSCRIPTION_NOT_APPROVED_OPTIONS.REMOVE_TRANSCRIPTION) {
          this.$emit("sendTranscriptionApproval", {
            aiTranscriptRemoved : true,
            aiTranscriptApproved: false,
            id                  : this.pMessage.id
          })
        } else if (this.isTranscriptApprovedByReporter === TRANSCRIPTION_NOT_APPROVED_OPTIONS.KEEP_TRANSCRIPTION) {
          this.$emit("sendTranscriptionApproval", {
            aiTranscriptRemoved : false,
            aiTranscriptApproved: false,
            id                  : this.pMessage.id
          })
        }
      }
    },
    handleCancel() {
      this.reporterApprovedTranscript           = null
      this.approvedTranscriptOptions            = null
      this.showRegisterTranscriptApprovalDialog = false
    },
    handleTranscriptApproval() {
      const approvalData = {
        id                  : this.pMessage.id,
        aiTranscriptApproved: false,
        voiceFileRemoved    : false,
        aiTranscriptRemoved : false
      }
      if (this.reporterApprovedTranscript) {
        approvalData.aiTranscriptApproved = true
        if (this.approvedTranscriptOptions === RECEIVE_VOICE_FILE_TRANSCRIPTION_APPROVAL_GOTTEN.ONLY_TRANSCRIPT) {
          approvalData.voiceFileRemoved = true
        }
      } else {
        if (this.approvedTranscriptOptions === this.onlyVoiceFile) {
          approvalData.aiTranscriptRemoved = true
        } else if (this.approvedTranscriptOptions === this.onlyTranscript) {
          approvalData.voiceFileRemoved = true
        }
      }
      this.$emit("sendTranscriptionApproval", approvalData)
    },
    async handleUpdateCurrentTime(currentIndex) {
      this.conversationMessageItem.forEach((conversation, index) => {
        this.$set(conversation, "active", index === currentIndex)
      })
      for (const conversation of this.conversationMessageItem) {
        if (conversation.active) {
          await this.$nextTick(() => {
            const messageItemRefs = this.$refs[`dialogue_${conversation.startTimestamp}`]
            if (messageItemRefs && messageItemRefs.length) {
              messageItemRefs[0].scrollIntoView({
                behavior: "smooth",
                block   : "center"
              })
            }
          })
        }
      }
    },
    getDialogueClass(from) {
      return from === "REPORTER" ? ["dialog--reporter", "float-left"] : ["dialog--ai", "float-right"]
    },
    getDialogueText(dialogue) {
      return !this.pIsReportNotInClientDefaultLanguage
        || !this.showConversationInClientDefaultLanguage ? dialogue.transcription : dialogue.translatedTranscription
    },
    handleQuestionsEnter() {
      if (this.currentQuestion.length && this.currentQuestion.length <= this.questionCharacterLimit) {
        this.$emit("updateQuestions", {
          questions: [
            ...this.questions,
            this.currentQuestion
          ],
          messageId    : this.pMessage.id,
          messageItemId: this.originalQuestionsMessageItems.id
        })
        this.currentQuestion = ""
      }
    },
    handleQuestionDelete(index){
      this.$emit("updateQuestions", {
        questions    : this.questions.filter((_, i) => i !== index),
        messageId    : this.pMessage.id,
        messageItemId: this.originalQuestionsMessageItems.id
      })
    }
  },
  watch: {
    immediate     : true,
    pHighlightText: {
      handler: async function(newValue) {
        if (!this.messageItemToBeDisplayed?.value) return
        this.citationFound = false
        if (newValue) {
          this.citations     = newValue.split(".").map(citation => citation.trim()).filter(citation => citation)
          this.citationFound = this.citations.some(citation => this.messageItemToBeDisplayed.value.toLowerCase()
            .includes(citation.toLowerCase()))
        }

        if (this.citationFound) {
          this.messageSegments       = this.messageItemToBeDisplayed.value.split(".")
            .map(segment => segment.trim()).filter(segment => segment)
          const toBeHighlightedIndex = this.messageSegments.findIndex(segment => {
            return segment.toLowerCase().trim() && segment.toLowerCase()
              .includes(this.citations[0].toLowerCase().trim())
          })

          await this.$nextTick(() => {
            const messageItemRefs = this.$refs[`${this.messageItemToBeDisplayed.id}_segment_text_message_${toBeHighlightedIndex}`]
            if(messageItemRefs && messageItemRefs.length) {
              messageItemRefs[0].scrollIntoView({
                behavior: "smooth",
                block   : "center"
              })
            }
          })
        }
      }
    },
    pIsMessageAdded: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.isDraftMessage) {
          this.editDraftMessage = newValue
        }
      }
    },
    pMessage: {
      immediate: true,
      deep     : true,
      handler  : function(newValue) {
        this.attachments = new Array()
        if (newValue.items) {
          const humanMessageItems   = new Array()
          const machineMessageItems = new Array()
          for (const messageItem of newValue.items) {
            if (messageItem.type === "audio") {
              if (messageItem.version === "machine") {
                this.machineRecordedAudionMessageItem = messageItem
              } else {
                this.originalAudioMessageItem = messageItem
              }
            } else if (messageItem.type === "text") {
              if (messageItem.version === "original") {
                this.originalTextMessageItem = messageItem
              } else if ( messageItem.version === "machine" ) {
                machineMessageItems.push(messageItem)
              } else if ( messageItem.version === "human" ) {
                humanMessageItems.push(messageItem)
              }
            } else if (messageItem.type === "conversation") {
              this.conversationMessageItem = JSON.parse(JSON.stringify(messageItem.value))
            } else if (messageItem.type === "questions") {
              if (messageItem.version === "original") {
                this.originalQuestionsMessageItems = messageItem
                this.questions                     = this.originalQuestionsMessageItems.value
              } else {
                this.translatedQuestions = messageItem.value
              }
            } else {
              this.attachments.push(messageItem)
            }
          }
          if (machineMessageItems.length) {
            for (const machineMessageItem of machineMessageItems) {
              this.expandedMachineItems.push(machineMessageItem.id)
              if (machineMessageItem.processedFromId === this.originalAudioMessageItem?.id) {
                this.machineTranscribedTextMessageItem = machineMessageItem
              } else {
                this.machineTranslatedTextMessageItem = machineMessageItem
              }
            }
          }
          if (humanMessageItems.length) {
            for (const humanMessageItem of humanMessageItems) {
              this.expandedHumanItems.push(humanMessageItem.id)
              if (humanMessageItem.processedFromId === this.originalAudioMessageItem?.id) {
                this.humanTranscribedTextMessageItem = humanMessageItem
              } else {
                this.humanTranslatedTextMessageItem = humanMessageItem
              }
            }
          }
        }
      }
    },
    editDraftMessage: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localDraftTextMessageValue   = this.originalTextMessageItem.value
          this.draftMessageAutoSaveInterval = setInterval(() => {
            if (this.localDraftTextMessageValue !== this.originalTextMessageItem.value) {
              this.draftTextMessageLastSavedTime = new Date()
              this.$emit("saveMessageItem", {
                id   : this.originalTextMessageItem.id,
                value: this.localDraftTextMessageValue
              })
            }
          }, 5000)
        } else if (this.draftMessageAutoSaveInterval) {
          clearInterval(this.draftMessageAutoSaveInterval)
        }
        this.$emit("editingMessage", newValue)
      }
    },
    pIsSendingMessage: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.SEND_MESSAGE_TO_REPORTER.pActions[1].buttonProps.loading = newValue
        if (!newValue && this.pIsMessageSent) {
          this.editDraftMessage                = false
          this.showSendMessageToReporterDialog = false
        }
      }
    },
    pIsRemovingMessage: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.DISCARD_DRAFT_MESSAGE.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.DISCARD_DRAFT_MESSAGE.pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsTranslationStatusUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showRedoHumanTranslationDialog = false
        }
      }
    },
    pIsUpdatingTranslationStatus: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsTranslationAdded: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showRequestHumanTranslationDialog = false
        }
      }
    },
    pIsAddingTranslation: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REQUEST_HUMAN_TRANSLATION.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REQUEST_HUMAN_TRANSLATION.pActions[0].buttonProps.disabled = newValue
      }
    },
    translationRedoReason: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[1].buttonProps.disabled = !newValue
      }
    },
    selectedTranscriptApprovalValue: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === TRANSCRIPTION_APPROVAL_STATUS.APPROVED) {
          this.transcriptApprovalAndFileStorageToBeChosen = true
        } else {
          this.transcriptApprovalAndFileStorageToBeChosen = false
          this.isTranscriptApprovedByReporter             = null
        }
      }
    },
    pIsMessageApprovalUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showTranscriptApprovalAndFileStorageDialog = false
          this.showRegisterTranscriptApprovalDialog       = false
        }
      }
    },
    reporterApprovedTranscript: {
      immediate: true,
      handler  : function() {
        this.approvedTranscriptOptions = null
      }
    }
  }
}